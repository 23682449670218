
  #root {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    justify-content: space-between;
  }

  .content {
    height: 100%;
    overflow: auto;
  }

  .content-wrapper {
    margin: 0 auto;
    width: 1200px;
    max-width: 100%;
    padding: 20px;
    // smooth transition
    transition: width .3s ease-in-out;
    -webkit-transition: width .3s ease-in-out;
    -moz-transition: width .3s ease-in-out;
    -ms-transition: width .3s ease-in-out;
  }

  h1 {
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 20px;
    font-size: 24px;
    color: $title-color;
  }

  .noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
  }

  @media (max-width: $desktop-device) {
    .content-wrapper {
      width: $tablet-device;
    }
  }

