
.footer {
    background-color: $footer-background;
    color: $footer-color;
    min-height: 60px;
    &-wrapper {
      min-height: 60px;
      height: 100%;
      width: 100%;
      background-color: transparent;
      background-image: url('../../logos/ebu_identity.svg');
      background-position: 100% 0;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }