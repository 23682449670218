

.users {
    margin-top: 40px;
    margin-bottom: 40px;
    .users-list {
        span {
            font-size: 0.8rem;
        }
        .users-header {
            background-color: #ececec;
            border-bottom: 2px solid $dark-brown;
            .cell {
                display: flex;
                flex-direction: column;
                justify-content: space-around;

                font-weight: bold;
                color: $dark-brown;
                padding: 10px;

                &:hover {
                    svg.arrow {
                        opacity:1;
                    }
                }
                &.selected {
                    color: $black;
                    svg.arrow {
                        opacity:1;
                    }
                }
            }
            svg.arrow {
                transition: 0.2s ease-in-out;
                vertical-align: middle;
                opacity:0;
            }
            
            .upside-down {
                transform: rotateZ(180deg);
            }
            .user-data {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                cursor: pointer;
                max-width: unset;
                width:auto;
                line-height: 24px;
            }
            .user-data-wrapper {
                width:100%;
                display:flex;
                justify-content: center;
            }
        }
        .users-list-items {
            .list-item {
                padding: 10px 0;
                border-bottom: 1px dotted $dark-brown;
                cursor: pointer;
                &.activebutton {
                    color: $red;
                }
                .name {
                    span {
                        display: inline-block;
                        width:50%;
                        padding:0px 0px 0px 10px;
                        &:first-child{
                            text-align: right;
                            padding:0px 10px 0 0;
                        }
                    }
                }
                &.visible {
                    background-color: white;
                    .user-data {
                        margin-top: 15px;
                    }
                    .group_name {
                        margin-top: 10px;
                    }
                    .name {
                        color: $black;
                    }
                    .email {
                        color: $black;
                    }
                    .user-profiles {
                        .section {
                            border-radius: 5px;
                            margin-top: 10px;
                            padding-top: 10px;
                            padding-left: 10px;
                        }
                        .section-0 {
                            background-color: $light-grey;
                        }
                        .section-1 {
                           background-color: $light-grey;
                        }
                    }
                }
                .user-data {
                    width: 50%;
                    .email {
                        margin: 20px;
                        font-size: 0.8rem;
                        text-align: center;
                    }
                }
                .user-profiles {
                    width: 50%;
                    .group-edit-section {
                        margin-right: 10px
                    }
                }
            }
        }
    }
}
.list-item {
    color: #333333;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    min-height: 40px;
    .cell {
        width: 100%;
    }
}

div[tooltip].group_name::after {
    content : attr(tooltip);
    display:block;
    position: relative;
    visibility: hidden;
    top: 0px; 
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background: rgba(0,0,0,.8);
    border-radius: 5px;
    padding: 5px 7px;
    font-weight: normal;
    font-size: 0.7rem;
    color: white;
    text-transform: none;
    line-height: initial;
    white-space: nowrap;
    transition: opacity 0.1s ease-in-out;
    opacity: 0;
    white-space: pre-line;
}
div[tooltip].group_name:hover::after {
    opacity: 1;
    visibility: visible;
}

.user {
    .checkbox-component {
        border: 1px solid $dark-brown;
        content: '';
        width: 16px;
        height: 16px;
        display: inline-table;
        margin-right: 20px;
        line-height: 0px;
    }
    .checkbox-component svg {
        width: 14px;
        height: 14px;   
        margin: 0px;
        padding: 0px;
        line-height: 0px;
        color: $light-grey;
    }
    .group_name.selected {

        .checkbox-component svg {
            color: $dark-brown;
        }
        .item-name {
            color: $light-black;
        }
    }
}


.group-edit-section {
    .button-wrap {
        text-align: right;
        button {
            margin-top: 10px;
            background: #1A3E93;
            border: 0px;
            height: 36px;
            color: white;
            font-size: 0.9rem;
            font-weight: bold;
            text-transform: uppercase;
            padding: 0 10px;
            flex-shrink: 0;
            cursor: pointer;
            border-radius: 5px;
        }
        button.inactive {
            background: #f0f0f0;
            cursor: default;
            font-weight: bold;
            color: #999999;
            z-index: 0;
        }
    }
}
