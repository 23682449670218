

// Base variables
$white: #fff;
$black: #000;
$light-black: #292929;
$grey: #e8e8e8;
$light-grey: #F2F2F2;
$primary: #1b4098;
$blue: #1A3E93;
$dark-brown: #4f4f4f;
$light-brown: #8d8d8d;
$red: #f55643;


// Header
$header-background: $blue;
$header-color: $white;
$header-hover-background: $white;
$header-hover-color: $light-brown;

// Body
$background: $light-grey;
$link: $blue;
$title-color: $dark-brown;
$empty-color: $dark-brown;

// Search
$search-background: $white;
$search-border: $primary;
$search-logo:  $primary;
$noresults-color: $light-brown;
$filters-cta-color: $dark-brown;
$filters-background-color: $white;
$filters-button-background: $blue;
$filters-button-color: $white;

// Footer
$footer-background: $light-brown;
$footer-color: $white;



// Breakpoints
$desktop-device: 1200px;
$tablet-device: 768px;
$mobile-device: 400px;
