@import './_variables.scss';

*, *::before, *::after {
  box-sizing: border-box;
}

html {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  min-height: 100%;
  height: 100%;
}

body {
  margin: 0;
  background-color: $background;
  height: 100%;
}

ul,
ol {
  padding: 0;
  margin: 0;
  list-style: none;
}

a {
  text-decoration: none;
  color: $link;
}

h2 {
  font-size: 24px;
  line-height: 32px;
}

h2,
h3,
h4 {
  margin-top: 0;
}

p {
  margin: 0 0 10px 0;

  &:last-of-type {
    margin-bottom: 0;
  }
}

input,
select,
textarea,
button {
  font-family: 'proxima-nova', Arial, Helvetica;
}
input {
  letter-spacing: 0.3px;

  &::placeholder {
    color: $grey;
  }
}
