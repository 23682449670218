
.search {
    position: relative;
    width: calc(100% - 120px);
    &-input {
        height: 33px;
        color: #000;
        background: $search-background;
        width: 100%;
        padding: 0 45px 0 8px;
        font-size: 14px;
        outline: 0;
        margin: 0;
        border: 1px solid $search-border;
        border-radius: 25px;
    }
    &-button {
        background-color: rgba(0,0,0,0);
        border: 0;
        height: 100%;
        left: auto;
        position: absolute;
        right: 0;
        width: 40px;
        z-index: 2;
        height: 33px;
    }
    &-icon {
        color: $search-logo;
        height: 33px;
    }
}


.search-input__submit-button {
    background-color: rgba(0,0,0,0);
    border: 0;
    height: 100%;
    left: auto;
    position: absolute;
    right: 0;
    width: 40px;
    z-index: 2;
}


.emptyresults {  
    text-align: center;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .emptyresults-text {
      color: $empty-color;
    }
  }

.noresults {
    .emptyresults-text {
      color: $noresults-color;
    }
}

.loading {
    text-align: center;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0;
    margin: 0;
    width: 100%;
}