
#overlay{
    background: transparent;
    position:fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 10000;
    transition: 0.2s ease-in-out;
}
#overlay.hidden {
    opacity:0;
    pointer-events: none;
}
#overlay.with-notification{
    height:auto;
    bottom: auto;
}
#overlay.with-notification .close-zone, #overlay.with-notification .overlay-container{
    display: none;
}

.close-zone {
    position: absolute;
    top:0;bottom:0;left:0px;right:0px;
    background: #00000044;
}

.notification {
    position: absolute;
    top: 30px;
    left:50%;
    min-width:300px;
    width:50%;
    background: white;
    box-shadow: 0 0 20px rgba(0,0,0,.1);
    list-style: none;
    padding:0px;
    border-radius: 4px;
    overflow: hidden;
    margin:0px;
    -webkit-transform: translate(-50%,0);
    -ms-transform: translate(-50%,0);
    transform: translate(-50%,0);
    transition: 0.2s ease-in-out;
    min-height: 60px;
    z-index: 10000000;
}
.notification.hidden {
    top:-100px;
    opacity: 0;
}
.notification .fake {
    cursor: default;
    background:transparent;
    border:0px;
    min-height: 60px;
    font-size: 0.9rem;
    text-align:left;
}
.notification span {
    margin-left: 45px;
    margin-right: 40px;
    padding: 10px 0;
    display: inline-block;
    vertical-align: middle;
}
.notification .icon-wrapper {
    position: absolute;
    left: 0px;
    width: 50px;
    top: 0px;
    bottom: 0px;
}
.notification .icon-wrapper svg{
    position: absolute;
    left: 50%;
    width: 30px;
    height: 30px;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}
.notification button.close {
    position: absolute;
    right: 0px;
    width: 40px;
    height: 40px;
    top: 50%;
    -webkit-transform: translate(0,-50%);
    -ms-transform: translate(0,-50%);
    transform: translate(0,-50%);
    background: transparent;
    border:0px;
    cursor: pointer;
}
.notification button svg{
    position: absolute;
    left: 50%;
    width: 16px;
    height: 16px;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}
.notification.error {
    background: #fcebeb;
}
.notification.error .icon-wrapper svg{
    color: #931a1a;
}

.notification.info {
    background: #ebf0fc;
}
.notification.info .icon-wrapper svg{
    color: #1A3E93;
}

.notification.warning {
    background: #fcf5eb;
}
.notification.warning .icon-wrapper svg{
    color: rgb(206, 137, 48);
}

.overlay-container {
    position: absolute;
    top: 30px;
    left:50%;
    background: white;
    box-shadow: 0 0 20px rgba(0,0,0,.1);
    list-style: none;
    padding:40px 0 0 0;
    border-radius: 4px;
    overflow: hidden;
    margin:0px;
    width: max-content;
    -webkit-transform: translate(-50%,0);
    -ms-transform: translate(-50%,0);
    transform: translate(-50%,0);
    transition: 0.2s ease-in-out;
}
.overlay-container.hidden {
    top:100%;
}

.overlay-container .scroller{
    overflow: auto;
    width:315px;
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    top:40px;
    bottom:46px;
    left:0px;
}
.overlay-container header {
    background: #f0f0f0;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    height: 40px;
    line-height: 40px;
    color: #333333;
    font-weight: 600;
    font-size: 0.9rem;
    z-index: 1;
}
.overlay-container header > svg{
    margin: 0px 8px -2px;
}
.overlay-container.language header > svg{
    margin: 0px 8px -5px;
    width: 20px;
}
.overlay-container.datetime header > svg{
    margin: 0px 8px -5px;
    width: 20px;
}

.overlay-container button.close {
    position: absolute;
    right: 0px;
    top: 0px;
    border: 0px;
    color: #333333;
    padding:10px 0px;
    cursor: pointer;
    font-weight: bold;
    font-size: 0.8rem;
    background: #f0f0f0;
    margin:0px;
    border-radius: 0px 4px 0 0;
    width: 40px;
    height: 40px;
}

.overlay-container.language {
    min-width: 200px;
    padding-bottom:40px;
}

.overlay-container.language footer{
    height:40px;
    line-height: 40px;
    text-align: center;
    font-size: 0.8rem;
}
.overlay-container.language footer svg{
    height:12px;
}
.overlay-container.organisation {
    width: 315px;
    height: 500px;
}

.overlay-container.organisation div{
    width: 95px;
    height: 100px;
    position: relative;
    margin:5px;
    cursor: pointer;
    border-radius: 5px;
    opacity: 0.5;
}
.overlay-container.organisation div.selected {
    opacity: 1;
}
.overlay-container.organisation div:hover{
    background: #f0f0f0;
    opacity: 1;
}
.overlay-container.organisation div::after{
    content: '';
    position: absolute;
    background-color: #1A3E93;
    border-radius: 10px;
    bottom: 0px;
    right: 0px;
    width:20px;
    height: 20px;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 8 8'%3e%3cpath fill='%23f0f0f0' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
    background-position: center;
    background-repeat: no-repeat;
    transition: 0.2s ease-in-out;
    opacity: 0;
}
.overlay-container.organisation div.selected::after{
    bottom: 2px;
    right: 2px;
    opacity: 1;
} 
.overlay-container.organisation div svg{
    max-width: 80px;
    max-height: 80px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.overlay-container footer {
    width: 100%;
    position: absolute;
    left:0px;
    bottom:0px;
    background:#e6e6e6;
}
.overlay-container footer button {
    background: transparent;
    border: 0px;
    color: #333333;
    padding:10px 0px;
    cursor: pointer;
    flex-grow: 1;
    font-weight: bold;
    font-size: 0.8rem;
    background: #ffffff;
    transition: 0.2s ease-in-out;
    margin:5px;
    border-radius: 4px;
    width: 60%;
}
.overlay-container.organisation footer button svg {
    margin-bottom: -6px;
    margin-top: -6px;
    width: 24px;
    margin-right: 5px;
}
.overlay-container.organisation footer button.deselect{
    width: calc(40% - 15px);
    margin-right:0;
}
.overlay-container.organisation footer button:hover, .overlay-container button.close:hover{
    color: #1A3E93;
    background: #f0f0f0;
}
.overlay-container.organisation footer button.disabled {
    color: #666666;
    background: #f0f0f0;
    cursor: default;
}
.overlay-container.organisation footer button.hidden {
    display: none;
}





.selector-form {
    list-style: none;
    padding:0px;
    border-radius: 4px;
    overflow: hidden;
    margin:0px;
    width: max-content;
    min-width: 200px;
    font-weight: bold;
    font-size: 0.7rem;
}
.selector-form li {
    color: #333333;
    line-height: 40px;
    font-size: 0.8rem;
    padding-right:50px;
    cursor: pointer;
}
.selector-form li.selected{
    color: #1A3E93;
    line-height: 40px;
    font-size: 0.8rem;
    position: relative;
}
.selector-form li:hover{
    background: #f0f0f0;
}
.selector-form li::before {
    content: ""; 
    display:inline-block;
    width: 1rem;
    height: 1rem;
    background-color: #e0e0e0;
    border:1px solid #888888;
    border-radius: 0.8rem;
    vertical-align: middle;
    margin-bottom: 4px;
    margin-right:10px;
    margin-left:10px;
}
.selector-form li.selected::before {
    background-color: #f0f0f0;
    border:1px solid #1A3E93;
}
.selector-form li.selected::after {
    content: ""; 
    position: absolute;
    top:50%;
    left:0.30rem;
    -webkit-transform: translate(0,-50%);
    -ms-transform: translate(0,-50%);
    transform: translate(0,-50%);
    width: 0.5rem;
    height: 0.5rem;
    background-color: #1A3E93;
    border-radius: 0.8rem;
    vertical-align: middle;
    margin-bottom: 4px;
    margin-right:10px;
    margin-left:10px;
}
.selector-form button {
    display:block;
    width: 100%;
    text-align: center;
    color: #333333;
    line-height: 40px;
    font-size: 0.8rem;
    cursor: pointer;
    font-weight: bold;
    background: transparent;
    border:0;
}
.selector-form button:hover {
    color: #1A3E93;
    background: #eeeeee;
}


.overlay-container.datetime {
    max-width:326.3px;
}

.overlay-container.datetime button.validate{
    border: 0px;
    color: #333333;
    padding:10px 0px;
    cursor: pointer;
    font-weight: bold;
    font-size: 0.8rem;
    background: #f0f0f0;
    margin:0px;
    border-radius: 4px;
    width: calc(100% - 8px);
    text-overflow: clip;
    position:relative;
    margin: 0 0 4px 4px;
}
.overlay-container.datetime button.validate:hover {
    color: #1A3E93;
    background: #dfdfdf;
}
.overlay-container.datetime button.validate svg{
    margin:0 5px -5px 0;
}


.terms-background {
    position: fixed;
    top:0px;
    left:0px;
    right:0px;
    bottom:0px;
    background: #1A3E93;
    display: flex;
    align-items: center;
    justify-content: center;
}
.terms-background.closable {
    z-index: 100;
    background: #00000070; 
    cursor:pointer;   
}
.terms-background.closable .terms .text-wrapper {
    margin-bottom: 10px;
}

.terms {
    background: #ffffff;
    box-shadow: 0 0 20px rgba(0,0,0,.2);
    width:400px;
    height:400px;
    border-radius: 5px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    cursor: auto;
}
.terms .text-wrapper{
    margin:0 10px;
    border: 1px solid #dddddd;
    padding:5px;
    overflow-y: auto;
    text-align: left;
    background: #f2f2f2;
}
.terms button {
    background: #1A3E93;
    border: 0px;
    height: 40px;
    color:white;
    font-size: 1rem;
    font-weight:bold;
    text-transform: uppercase;
    margin:10px auto 10px auto;
    padding: 0 20px;
    border-radius: 4px;
    flex-shrink: 0;
    cursor: pointer;
}
.terms button.close {
    position: absolute;
    right: 0px;
    transform: rotateZ(45deg);
    background: transparent;
    color: #1A3E93;
    font-size: 2rem;
}

.terms h1{
    font-size: 2.rem;
    text-align: center;
    color: #1A3E93;
    margin: 10px;
}
.terms h1 span{
    display: block;
    font-size: 0.8rem;
    font-style: italic;
    font-weight: normal;
}

.terms h2{
    font-size: 1rem;
    text-align: left;
    color: #444444;
}
.terms p {
    font-size: 0.8rem;
    color: #333333;
}
.terms hr {
    border: 1px solid #cccccc;
    border-top:0px;
}
.terms .small {
    font-size: 0.6rem;
}


.unauthorized {
    background: #ffffff;
    box-shadow: 0 0 20px rgba(0,0,0,.2);
    width:400px;
    border-radius: 5px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.unauthorized button {
    background: #1A3E93;
    border: 0px;
    height: 40px;
    color:white;
    font-size: 1rem;
    font-weight:bold;
    text-transform: uppercase;
    margin:10px auto 10px auto;
    padding: 0 20px;
    border-radius: 4px;
    flex-shrink: 0;
    cursor: pointer;
    vertical-align: middle;
}
.unauthorized button svg {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
}

.unauthorized h1{
    font-size: 2.rem;
    text-align: center;
    color: #931a1a;
    margin: 10px;
}
.unauthorized h1 span{
    display: block;
    margin-top:5px;
    font-size: 0.9rem;
    font-weight: normal;
    color: #333333;
}

@media only screen and (max-width: 480px){
    .terms, .unauthorized {
        position: fixed;
        top:0px;
        bottom:0px;
        left:0px;
        right: 0px;
        border-radius: 0px;
        margin:0px;
        width: auto;
        height: auto;
    }
    .unauthorized {
        justify-content: center;
    }
}