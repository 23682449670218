.filters {
    .filters-wrapper {
        margin-top: -32px;
        cursor: pointer;
        .filters-cta {
            line-height: 30px;
            vertical-align: middle;
            background:$filters-button-background;
            color:$filters-button-color;
            border-radius: 5px ;
            float:right;
            padding: 0px 5px;
            width:110px;
            &.selected{
                padding-bottom: 5px;
                border-radius: 5px 5px 0 0;
                border-bottom: 0px;
                z-index: 1000;
                position: relative;
                background:$filters-background-color;
                color: $filters-cta-color;
            }
            .fiters-cta-wrapper {
                text-align: right;
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                font-weight: bold;
                font-size: 0.8rem;

                .filter-icon {
                    vertical-align: middle;
                    padding:0 3px;
                }
                .filters-text {
                    line-height: 30px;
                }
                svg.arrow {
                    transition: 0.2s ease-in-out;
                    vertical-align: middle;
                }
                .upside-down {
                    transform: rotateZ(180deg);
                }
            }
        }

        .filters-container {
            .filters-block {
                padding: 10px 30px;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                font-size: 0.8rem;
                line-height: 1.4rem;
                .show-groups {
                    display: flex;
                    flex-direction: column;
                    padding-left:20px;
                }
            }
            overflow:hidden;
            -webkit-transition: max-height 0.4s ease-out;
            -moz-transition: max-height 0.4s ease-out;
            -o-transition: max-height 0.4s ease-out;
            transition:max-height 0.4s ease-out;
            height:auto;
            max-height:500px;
            background-color: $filters-background-color;
            clear: both;
            float: right;
            border-radius: 10px 0 10px 10px;
            margin-bottom: 10px;
            &.hide {
                transition:none;
                max-height:0;
                border:0px;
            }
        }

    }
}


.filters {
    .checkbox-component {
        border: 1px solid $primary;
        content: '';
        width: 16px;
        height: 16px;
        display: inline-table;
        margin-right: 10px;
        line-height: 0px;
    }
    .checkbox-component svg {
        width: 14px;
        height: 14px;   
        margin: 0px;
        padding: 0px;
        line-height: 0px;
        color: $filters-background-color;
    }
    .checkbox-component.selected svg {
        color: $primary;
    }
}

