header {
    background-color: $header-background;
    color: $header-color;

    &.topPosition {
        position: absolute;
        top: 0;
        width: 100%;
    }

    .header-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        min-height: 68px;
        padding: 13px;
    
        width: 100%;
        height: 100%;
    }

    .user {
        border: 1px solid rgba(255, 255, 255, 0.5);
        text-transform: none;
        font-weight: bold;
        font-size: 0.7rem;
        line-height: 30px;
        border-radius: 4px;
        margin-right: 0px;
        display: flex;
        align-items: center;
        padding: 0px;
        margin-left: 10px;
        .user-info {
            svg {
                margin: 0 5px 0 5px;
            }
        }
        button {
            border: 0px;
            border-left: 1px solid rgba(255, 255, 255, 0.5);
            background: transparent;
            color: white;
            margin: 0px;
            height: 30px;
            cursor: pointer;
            margin-left: 10px;
        }
        button:hover {
            background: $header-hover-background;
            color: $header-hover-color;
        }
        svg {
            display: inline-block;
            vertical-align: middle;
            margin: 0px;
            width: 20px;
        }
    }
}// header  

  @media (max-width: $mobile-device) {
    header {
        .user {
            border: none;
            .user-info {
                display: none;
            }
            button {
                border: 1px solid rgba(255, 255, 255, 0.5);
                border-radius: 4px;
            }
        }
    }
  }
